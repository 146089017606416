<template>
   <v-app-bar app clipped-left v-bind="$attrs" color="natural lighten-5">
       <v-app-bar-title>
       <v-img width="94" contain :src="require('@/assets/logo.svg')"/>
       </v-app-bar-title>
        <v-spacer/>
        <ChatMenu v-if="chatThreadLists.length > 0" @onClick="viewMessage"/>
        <MainMenu @onClick="dialog=true"/>
        <v-btn icon>
<v-icon color="natural darken-4">mdi-help-circle-outline</v-icon>
       </v-btn>
       <v-divider class="pr-3 mr-3" vertical/>
     <UserMenu @userProfile="profileDialog=true" />
       <v-dialog v-model="dialog" fullscreen scrollable>
          <DialogCard @onClose="dialog=false"/>
      </v-dialog>
      <v-dialog v-model="profileDialog" fullscreen scrollable>
        <UserDialog @onClose="profileDialog=false"/>
      </v-dialog>
        <v-dialog v-model="messageDialog" width="600" persistent scrollable >
      <ChatAreaCard @onClose="messageDialog=false" :key="messageDialog" v-bind="currentPatient"/>
    </v-dialog>
   </v-app-bar>
</template>

<script>
import MainMenu from '@/components/common/MainMenu'
import UserMenu from '@/components/common/UserMenu'
import ChatMenu from '@/components/common/ChatMenu'
import DialogCard from '@/components/legal/DialogCard'
import UserDialog from '@/components/user/UserDialog'
import ChatAreaCard from '@/components/chat/ChatAreaCard'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    MainMenu,
    UserMenu,
    DialogCard,
    UserDialog,
    ChatMenu,
    ChatAreaCard
  },
  data () {
    return {
      dialog: false,
      profileDialog: false,
      chatService: true,
      messageDialog: false,
      currentPatient: null
    }
  },
  methods: {
    viewMessage (item) {
      this.currentPatient = item
      this.messageDialog = true
    }
  },
  computed: {
    ...mapFields(['chatThreadLists'])
  }

}
</script>

<style>

</style>
