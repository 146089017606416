<template>
<v-app>
   <AppBar flat/>
   <Navigation/>

    <v-main class="natural lighten-2">
         <router-view></router-view>
    </v-main>
</v-app>

</template>

<script>
import AppBar from '@/components/common/AppBar'
import Navigation from '@/components/common/Navigation'
export default {
  components: {
    AppBar,
    Navigation
  }

}
</script>

<style>

</style>
