<template>
   <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
          <v-avatar
      color="primary"
      size="32"
       v-bind="attrs"
    v-on="on"
    >
     <v-img contain :src="require('@/assets/1.svg')"/>
    </v-avatar>
      </template>
      <v-list nav>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="menuActionClick(item.action)"
        >
          <v-list-item-title><v-icon>{{item.icon}}</v-icon>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  data () {
    return {
      items: [
        { title: 'ข้อมูลผู้ใช้งาน', icon: 'mdi-account', action: 'userProfile' },
        { title: 'ติดต่อเรา', icon: 'mdi-face-agent', action: 'contactUs' },
        { title: 'หน่วยบริการ', icon: 'mdi-office-building', action: 'selectTenant' },
        { title: 'ออกจากระบบ', icon: 'mdi-logout', action: 'logout' }
      ]
    }
  },
  methods: {
    menuActionClick (action) {
      switch (action) {
        case 'logout':
          this.$auth.logout()
          break
        case 'selectTenant':
          this.$router.push({ name: 'selecttenant' })
          break
        case 'userProfile':
          this.$emit('userProfile')
          break
        case 'contactUs':
          this.$router.push({ name: 'contactus' })
          break
      }
    }
  }
}
</script>

<style>

</style>
