<template>
  <v-card tile>
      <v-card-title>
<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
      <v-img contain max-height="600px"  :src="imgSrc"/>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String
    }
  }
}
</script>
