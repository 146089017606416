<template>
  <v-navigation-drawer app clipped permanent color="natural darken-4">
    <v-list-item class="pt-5 pb-5" :to="{name: 'selectsite'}">
      <v-list-item-avatar size="50">
        <v-img contain :src="require('@/assets/siteavatar.svg')" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="title text-title-2 natural--text text--lighten-5 text-wrap"
        >
          {{site.siteName}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="natural darken-2"></v-divider>
    <v-list  nav expand>
        <v-list-item-group
          v-model="selectedItem"
          active-class="primary"
          >
       <template v-for="item in items">
           <v-list-item v-if="!item.childs" :key="item.title" link :to="item.to">
                 <v-list-item-icon>
          <v-icon color="natural lighten-5">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="text-title-4 natural--text text--lighten-5"
            >{{ item.title }}</v-list-item-title
          >
        </v-list-item-content>
           </v-list-item>
        <v-list-group v-else :key="item.title"
        :value="true"
         no-action
      >
<template v-slot:prependIcon>
    <v-icon color="natural lighten-5">{{item.icon}}</v-icon>
</template>
      <template v-slot:activator>
          <v-list-item-title  class="text-title-4 natural--text text--lighten-5">{{item.title}}</v-list-item-title>
        </template>
            <v-list-item v-for="subitem in item.childs" :key="subitem.title" link :to="subitem.to">
        <v-list-item-content>
          <v-list-item-title
            class="text-title-4 natural--text text--lighten-5"
            >{{ subitem.title }}</v-list-item-title
          >
        </v-list-item-content>
           </v-list-item>
      </v-list-group>
       </template>
        </v-list-item-group>
    </v-list>
   <v-footer absolute class="natural darken-4 text-caption natural--text text--lighten-5"><div class="text-center">version {{version}}</div></v-footer>
  </v-navigation-drawer>
</template>

<script>
// import { getSitesPromise, patchSitePromise } from '@/api/' // getChatThread
// import { ChatClient } from '@azure/communication-chat'
// import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { mapFields } from 'vuex-map-fields'

export default {
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      selectedItem: 0,
      site: null,
      items: [
        { title: 'ข้อมูลทั่วไป', icon: 'mdi-home', to: { name: 'general' } },
        { title: 'วิเคราะห์', icon: 'mdi-view-dashboard', to: { name: 'analytic' } },
        {
          title: 'แบบสำรวจ',
          icon: 'mdi-text-box-search-outline',
          childs: [
            { title: 'แบบฟอร์ม', to: { name: 'formlist' } },
            { title: 'ผลลัพธ์', to: { name: 'formresult' } }
            // { title: 'รายงาน', to: { name: 'formreport' } }
          ]
        },
        {
          title: 'ติดตามข้อมูล',
          icon: 'mdi-monitor-multiple',
          childs: [
            { title: 'ติดตามข้อมูล', to: { name: 'monitor' } },
            { title: 'ผู้ป่วย', to: { name: 'patient' } }
          ]
        },
        { title: 'ประกาศ', icon: 'mdi-newspaper', to: { name: 'announcement' } },
        { title: 'จัดการหน่วยบริการ', icon: 'mdi-home-city-outline', to: { name: 'sitesettings' } },
        // { title: 'รายงานภาพรวม', icon: 'mdi-file-chart-outline', to: { name: 'report' } },
        // { title: 'วิเคราะห์เชิงลึก', icon: 'mdi-chart-pie', to: { name: 'analytics' } },
        { title: 'ข้อมูลลูกค้า', icon: 'mdi-account-details', to: { name: 'tenant' } }
      ]
    }
  },
  computed: {
    ...mapFields(['chatThreadLists'])
  },
  methods: {
    // async initChat () {
    //   const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
    //   const site = JSON.parse(sessionStorage.getItem('Site'))
    //   try {
    //     const siteInfo = await getSitesPromise({ tenantId: tenant.tenantId, siteId: site.siteId })
    //     if (siteInfo.data.code === 1) {
    //       const tokenInfo = await patchSitePromise({ tenantId: tenant.tenantId, siteId: site.siteId, id: siteInfo.data.result.items[0].id, etag: siteInfo.data.result.items[0]._etag }, {})
    //       if (tokenInfo.data.code === 1) {
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  },
  async created () {
    this.site = JSON.parse(sessionStorage.getItem('Site'))
    // this.initChat()
  }
}
</script>

<style lang="scss" scoped>

</style>
