<template>
  <v-card tile>
      <v-card-title class="elevation-2">
         <!-- <v-toolbar>
        <v-toolbar-title class="font-weight-bold natural--text text--darken-4">นโยบายการใช้งาน</v-toolbar-title>
      </v-toolbar> -->
      นโยบายการใช้งาน
       <v-spacer/>
          <v-btn text @click="$emit('onClose')">
              ปิดหน้านี้
              <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="2">
        <v-card outlined>
        <v-tabs vertical  v-model="tab">
        <v-tab
        v-for="item in items"
        :key="item.title"
        >
        {{item.title}}
        </v-tab>
        </v-tabs>
        </v-card>
          </v-col>
           <v-col cols="10">
             <v-card outlined>
              <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.title"
      >
        <v-card flat>
          <v-card-title class="text-h4 font-weight-bold natural--text text--darken-4">
            {{ item.title }}
             <v-tabs><v-tabs-slider color="secondary"></v-tabs-slider><v-tab></v-tab></v-tabs>
          </v-card-title>
          <v-card-text v-html="item.content">
            </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
             </v-card>
           </v-col>
          </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
import { getContentType } from '@/api/'

export default {
  data () {
    return {
      items: [
        { title: 'นโยบายความเป็นส่วนตัว', icon: '', content: null },
        { title: 'ตั้งค่าคุกกี้', icon: '', content: null },
        { title: 'เงื่อนไขการให้บริการ', icon: '', content: null },
        { title: 'ข้อมูลส่วนบุคคล', icon: '', content: null },
        { title: 'แจ้งประมวลผลข้อมูล', icon: '', content: null }
      ],
      tab: null,
      loading: false
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getContentType({ contentType: 'privacy_policy' }, message => {
        if (message.data.code === 1) {
          this.items[0].content = message.data.result[0].contentTh
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
      getContentType({ contentType: 'term_condition' }, message => {
        if (message.data.code === 1) {
          this.items[2].content = message.data.result[0].contentTh
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style lang="scss" scoped>

</style>
