<template>
  <v-card tile>
      <v-card-title class="font-weight-bold natural--text text--darken-4 elevation-2">
         <!-- <v-toolbar>
        <v-toolbar-title class="font-weight-bold natural--text text--darken-4">นโยบายการใช้งาน</v-toolbar-title>
      </v-toolbar> -->
      ข้อมูลผู้ใช้งาน
       <v-spacer/>
          <v-btn text @click="$emit('onClose')">
              ปิดหน้านี้
              <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="2">
        <v-card outlined>
        <v-tabs vertical  v-model="tab">
        <v-tab
        v-for="item in items"
        :key="item.tab"
        >
        <v-icon>{{item.icon}}</v-icon>
        {{item.title}}
        </v-tab>
        </v-tabs>
        </v-card>
          </v-col>
           <v-col cols="10">
             <v-card outlined>
              <v-tabs-items v-model="tab">
      <v-tab-item
        :key="0"
      >
        <v-card flat>
          <v-card-title class="text-h5 font-weight-bold natural--text text--darken-4">
            {{ items[0].title }}
          </v-card-title>
        <v-card-text>
              <v-card flat outlined >
                  <v-row no-gutter>
                       <v-col cols="12" md="2">
                             <div class="text-center pt-5">
                    <v-avatar size="128">
                        <v-img :src="require('@/assets/defaultsiteavatar.svg')"/>
                    </v-avatar>
                    </div>
                       </v-col>
                   <v-divider  inset vertical class="mt-3 mb-3"/>
                    <v-col cols="12" md="10">
                        <div class="pa-3">
                        <TextField dense title="ชื่อ นามสกุล" v-model="model.fullname"/>
                        <TextField dense title="อีเมล์" v-model="model.email"/>
                        <TextField dense title="เบอร์โทรศัพท์" v-model="model.mobile"/>
                        </div>
                    </v-col>
                  </v-row>
              </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn width="230" height="54" x-large color="primary">บันทึก</v-btn>
        </v-card-actions>
        </v-card>
      </v-tab-item>
       <v-tab-item
        :key="1"
      >
        <v-card flat>
          <v-card-title class="text-h4 font-weight-bold natural--text text--darken-4">
            {{ items[1].title }}
          </v-card-title>
          <v-card-text>
               <v-card flat outlined >
                  <v-row no-gutter>
                    <v-col cols="12" md="12">
                        <div class="pa-3">
                        <TextField dense title="รหัสผ่านใหม่" type="password"/>
                        <TextField dense title="ยืนยันรหัสผ่าน" type="password"/>
                        </div>
                    </v-col>
                  </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
            <v-btn width="230" height="54" x-large color="primary">บันทึก</v-btn>
            </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
             </v-card>
           </v-col>
          </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
// import { getContentType } from '@/api/'
import TextField from '@/components/common/TextField'

export default {
  components: {
    TextField
  },
  data () {
    return {
      items: [
        { tab: 0, title: 'ข้อมูลส่วนตัว', icon: 'mdi-account', content: null },
        { tab: 1, title: 'เปลี่ยนรหัส', icon: 'mdi-lock-outline', content: null }
      ],
      tab: null,
      loading: false,
      model: {}
    }
  },
  methods: {
    fetchData () {
      this.loading = false
      this.model = this.$auth.role
      // console.log(this.$auth.role)
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style lang="scss" scoped>

</style>
