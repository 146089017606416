<template>
   <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
         <v-icon color="natural darken-4">mdi-shield-account-outline</v-icon>
        </v-btn>
      </template>
      <v-list nav>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="$emit('onClick')"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>

export default {
  data () {
    return {
      items: [
        { title: 'นโยบายความเป็นส่วนตัว', icon: '' },
        { title: 'ตั้งค่าคุกกี้', icon: '' },
        { title: 'เงื่อนไขการให้บริการ', icon: '' },
        { title: 'ข้อมูลส่วนบุคคล', icon: '' },
        { title: 'แจ้งประมวลผลข้อมูล', icon: '' }
      ]
    }
  }
}
</script>

<style>

</style>
