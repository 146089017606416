<template>
   <v-menu offset-y style="z-index: 99999" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
         <v-badge
        :content="unreadItems.length"
        :value="unreadItems.length"
        color="primary"
        overlap
      >
       <v-icon color="natural darken-4">mdi-chat-outline</v-icon>
      </v-badge>
        </v-btn>
      </template>
      <v-toolbar flat>ข้อความถึงหน่วยบริการ</v-toolbar>
<v-responsive max-height="500" class="overflow-y-auto">
      <v-list nav >
        <v-list-item
          v-for="(item, index) in chatThreadLists"
          :key="index"
          @click="viewMessage(item)"
        >
        <v-list-item-avatar><v-img :src="require('@/assets/1.svg')"/></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.fullname }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.message">{{ item.message }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-list-item-action-text>{{ (item.createdOn)? $dayjs(item.createdOn).fromNow() : $dayjs(item.lastMessageReceivedOn).fromNow() }}</v-list-item-action-text>
                <v-btn
              :ripple="false"
              icon
            >
              <v-icon v-text="'mdi-email'" />
            </v-btn>
        </v-list-item-action>
        </v-list-item>
      </v-list>
</v-responsive>
    </v-menu>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { EventBus } from '@/plugins/event-bus'
import { isUserMatchingIdentity } from '@/plugins/communication'
// import { getChatThread } from '@/api/'

export default {
  data () {
    return {
      items: [],
      show: false,
      unreadItems: []
    }
  },
  computed: {
    ...mapFields(['chatThreadLists'])
  },
  methods: {
    viewMessage (item) {
      this.unreadItems = this.unreadItems.splice(1, this.unreadItems.indexOf(item.threadId))
      this.$emit('onClick', item)
    }
  },
  mounted () {
    EventBus.$on('chatThreadCreated', async (e) => {
      console.log('chatThreadCreated')
      // const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      // const site = JSON.parse(sessionStorage.getItem('Site'))
      // const thread = await getChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: '', offset: 0, limit: 100 })
      // const threadsData = thread.data.result.items.filter(el => el.chatThread && el.chatThread === e.threadId)
      // this.unreadItems.push(e.threadId)
      // if (this.chatThreadLists.filter(el => el.chatThread === threadsData[0].chatThread).length === 0) {
      //   this.chatThreadLists.push(threadsData[0])
      // }
    })
    EventBus.$on('chatMessageReceived', async (e) => {
      if (!isUserMatchingIdentity(window.communicationService.user, e.sender.communicationUserId)) {
        const threadLists = []
        this.chatThreadLists.forEach(element => {
          if (element.chatThread === e.threadId) {
            threadLists.push({ ...element, ...e, lastMessageReceivedOn: e.createdOn })
            if (this.unreadItems.indexOf(e.threadId) === -1) {
              this.unreadItems.push(e.threadId)
            }
          } else {
            threadLists.push(element)
          }
        })
        this.chatThreadLists = threadLists.sort(function (a, b) {
          var keyA = new Date(a.lastMessageReceivedOn).getTime()
          var keyB = new Date(b.lastMessageReceivedOn).getTime()
          if (keyA < keyB) return 1
          if (keyA > keyB) return -1
          return 0
        })
      }
    })
  }
}
</script>

<style>

</style>
