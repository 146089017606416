<template>
  <v-card height="500" color="natural lighten-2" >
        <v-card-title class="text-h5 font-weight-bold text-truncate"><v-avatar class="mr-2">  <v-img :src="require('@/assets/1.svg')"/></v-avatar>{{fullname}}<v-spacer/> <v-btn color="natural" class="ma-2" @click="onCallingDialog()" icon><v-icon>mdi-phone</v-icon></v-btn><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text  ref="chatContainer">
                          <v-file-input
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    ref="file"
                    name="file"
                    @change="select"
                    style="display: none"
                  />
          <v-skeleton-loader
          v-if="loading"
          v-model="loading"
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-row v-else dense color="white">
                  <template  v-for="(item, index) in messageList">
                  <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'text' && !(item.sender.communicationUserId === chatUser.communicationUserId)"
                    color="white"
                  >
                    <v-avatar size="25" class="ma-2">
                      <v-img :src="require('@/assets/2.svg')"/>
                    </v-avatar>
                    <v-chip id="chip-usage" class="text-wrap">{{ item.content.message }}</v-chip>
                    <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <small>{{item.senderDisplayName}}</small>
                  </v-col>
                  <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'html' && !(item.sender.communicationUserId === chatUser.communicationUserId)"
                    color="white"
                    align-self="start"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-avatar size="25" class="ma-2">
                      <v-img :src="require('@/assets/1.svg')"/>
                    </v-avatar>
                    <v-row>
                    <v-col>
                    <v-card max-width="200" @click="preview(item.content.message.replace(/amp;/g, ''))">
                    <v-img :src="item.content.message.replace(/amp;/g, '')"/>
                    </v-card>
                     <v-spacer/>
                    </v-col>
                    </v-row>
                  </v-col>

                   <v-col
                    cols="12"
                    :key="index"
                    v-if="item.type === 'text' && (item.sender.communicationUserId === chatUser.communicationUserId)"
                    class="text-right"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <!-- <v-card flat color="primary lighten-1" class="text-wrap rounded-xl"><v-card-text>{{ item.content.message }}</v-card-text></v-card> -->
                    <v-chip id="chip-usage" color="primary lighten-1" class="text-wrap">{{ item.content.message }}</v-chip>
                  </v-col>
                  <v-col
                    :key="index"
                    v-if="item.type === 'html' && (item.sender.communicationUserId === chatUser.communicationUserId)"
                    align-self="end"
                    class="text-right"
                  >
                   <small class="ma-2">{{
                      $dayjs(item.createdOn).format("HH:mm:ss DD-MM-YYYY")
                    }}</small>
                    <v-row>
                    <v-spacer/>
                    <v-col>
                    <v-card max-width="200" @click="preview(item.content.message.replace(/amp;/g, ''))">
                    <v-img :src="item.content.message.replace(/amp;/g, '')"/>
                    </v-card>
                    </v-col>
                    </v-row>
                  </v-col>

                  </template>
                   <v-col v-if="typingStatus" cols="12">กำลังพิมพ์....</v-col>
                   <v-col v-if="sending" cols="12">กำลังส่งข้อความ....</v-col>
                </v-row>
                </v-card-text>
      <v-card-actions>
           <v-row dense>
                     <v-col cols="auto">
                         <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
           <v-btn class="mx-auto" color="primary" large height="48"  outlined @click="choose"   v-bind="attrs" v-on="on">
                   <v-icon>mdi-file-image-outline</v-icon>
                 </v-btn>
      </template>
      <span>ส่งรูป</span>
    </v-tooltip>
               </v-col>
        <v-col>
      <v-text-field  v-model="message" solo @keydown="sendTyping" @keypress.enter="sendMessage"></v-text-field
      >
        </v-col>
        <v-col cols="auto">
          <v-btn class="mx-auto" color="primary" large height="48"  @click="sendMessage">ส่ง</v-btn>
        </v-col>
       </v-row>
      </v-card-actions>
      <v-dialog v-model="imgPreview" fullscreen>
<ChatImagePreview @onClose="imgPreview = false" :imgSrc="currentImg"/>
      </v-dialog>
      <v-dialog v-model="callingDialog" persistent width="400">
        <CallingDialogCard @onClose="callingDialog=false" :callingId="callingId" :fullname="fullname" :key="callingDialog"/>
      </v-dialog>
  </v-card>
</template>

<script>
import { EventBus } from '@/plugins/event-bus'
import { uploadChatImage } from '@/api/'
import { initCall } from '@/plugins/communication'
import ChatImagePreview from '@/components/chat/ChatImagePreview'
import reduce from 'image-blob-reduce'
import CallingDialogCard from '@/components/chat/CallingDialogCard'

export default {
  components: {
    ChatImagePreview,
    CallingDialogCard
  },
  props: {
    chatThread: {
      type: String
    },
    fullname: {
      type: String
    }
  },
  data () {
    return {
      messageList: [],
      message: '',
      chatUser: {
        communicationUserId: ''
      },
      lastSentTypingNotificationDate: 0,
      typingStatus: false,
      loading: false,
      rules: [
        (value) => !value || value.size < 10000000 || 'ขนาดรูปต้องต่ำกว่า 10 MB'
      ],
      currentImg: null,
      imgPreview: false,
      sending: false,
      callingDialog: false,
      callingId: null
    }
  },
  mounted () {
    this.fetchData()
  },
  destroyed () {
    console.log('onDestroy')
    EventBus.$off('chatMessageReceived', this.processMessage)
    EventBus.$off('typingIndicatorReceived')
    EventBus.$off('readReceiptReceived')
  },
  methods: {
    preview (img) {
      this.currentImg = img
      this.imgPreview = true
    },
    buildImg (str) {
      return str.replace(/amp;/g, '')
    },
    choose () {
      return this.$refs.file.$refs.input.click()
    },
    async select (file) {
      if (file) {
        try {
          this.sending = true
          // eslint-disable-next-line new-cap
          const rec = new reduce()
          const blob = await rec.toBlob(file, { max: 1000 })
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = async (e) => {
            const arrBase64 = e.target.result.split(',')
            const data = {
              fileType: arrBase64[0].match(/:(.*?);/)[1],
              fileEncode: arrBase64[1]
            }
            const resp = await uploadChatImage(data)
            if (resp.data.code === 1) {
              console.log(resp.data.result)
              const htmlStr = resp.data.result
              await this.sendHtmlMessage(htmlStr)
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.sending = false
        }
      }
    },
    async sendTyping () {
      const MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS = 8000
      const currentDate = new Date()
      const timeSinceLastSentTypingNotificationMs = currentDate.getTime() - this.lastSentTypingNotificationDate
      if (timeSinceLastSentTypingNotificationMs >= MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS) {
        await this.threads.sendTypingNotification()
        this.lastSentTypingNotificationDate = currentDate
      }
    },
    displayTyping () {
      this.typingStatus = true
    },
    scrollToEnd () {
      this.$nextTick(() => {
        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight
      })
    },
    async processMessage (e) {
      if (e.threadId === this.chatThread) {
        this.typingStatus = false
        if (e.sender.communicationUserId !== this.chatUser.communicationUserId) {
          // console.log(this.threads)
          await this.threads.sendReadReceipt({ chatMessageId: e.id })
        }
        this.getMessage()
      }
    },
    async getParticipants () {
      // <List users in a chat thread>
      const participants = this.threads.listParticipants()
      const arrayData = []
      for await (const participant of participants) {
        if (this.chatUser.communicationUserId !== participant.id.communicationUserId) {
          arrayData.push(participant.id.communicationUserId)
        }
      }
      return arrayData
    },
    async onCallingDialog () {
      this.loading = true
      const site = JSON.parse(sessionStorage.getItem('Site'))
      await initCall({ site: site })
      this.callingId = await this.getParticipants()
      // console.log(this.callingId[this.callingId.length - 1])
      this.callingId = this.callingId[this.callingId.length - 1]
      // this.callingId = '8:acs:86f53a83-613f-47db-ad24-460c94084617_0000000b-6c54-750f-8975-c93a0d00a36d'
      this.loading = false
      this.callingDialog = true
    },
    async fetchData () {
      try {
        if (window.chatClient) {
          this.loading = true
          this.chatUser = window.communicationService.user
          this.threads = window.chatClient.getChatThreadClient(this.chatThread)
          await this.getMessage()
          EventBus.$on('chatMessageReceived', this.processMessage)
          EventBus.$on('typingIndicatorReceived', async (e) => {
            if (e.threadId === this.chatThread) {
              if (e.sender.communicationUserId !== this.chatUser.communicationUserId) {
                this.displayTyping()
                this.scrollToEnd()
              }
            }
          })
          EventBus.$on('readReceiptReceived', async (e) => {
            if (e.threadId === this.chatThread) {
              console.log('readReceiptReceived')
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getMessage () {
      try {
        const messages = this.threads.listMessages()
        const messageData = []
        for await (const message of messages) {
          messageData.push(message)
        }
        this.messageList = messageData.sort(function (a, b) {
          var keyA = Number(a.sequenceId)
          var keyB = Number(b.sequenceId)
          // Compare the 2 dates
          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.sending = false
        this.scrollToEnd()
      }
    },
    async sendMessage () {
      if (this.message) {
        const sendMessageRequest =
            {
              content: this.message
            }
        const sendMessageOptions =
            {
              senderDisplayName: this.$auth.role.fullname,
              type: 'text'
            }
        this.sending = true
        await this.threads.sendMessage(sendMessageRequest, sendMessageOptions)
        this.message = ''
      }
    },
    async sendHtmlMessage (html) {
      if (html) {
        const sendMessageRequest =
            {
              content: html
            }
        const sendMessageOptions =
            {
              senderDisplayName: this.$auth.role.fullname,
              type: 'html'
            }
        this.sending = true
        await this.threads.sendMessage(sendMessageRequest, sendMessageOptions)
        this.message = ''
      }
    }
  }
}
</script>

<style scoped>
#chip-usage {
  margin: 8px;
  height: auto;
  min-height: 32px;
}
</style>
